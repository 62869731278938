// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-page-template-jsx": () => import("/solution/ANEX.Website.Osu/OT/src/templates/pageTemplate.jsx" /* webpackChunkName: "component---src-templates-page-template-jsx" */),
  "component---src-pages-404-jsx": () => import("/solution/ANEX.Website.Osu/OT/src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-faq-jsx": () => import("/solution/ANEX.Website.Osu/OT/src/pages/faq.jsx" /* webpackChunkName: "component---src-pages-faq-jsx" */),
  "component---src-pages-index-jsx": () => import("/solution/ANEX.Website.Osu/OT/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-sign-up-jsx": () => import("/solution/ANEX.Website.Osu/OT/src/pages/sign-up.jsx" /* webpackChunkName: "component---src-pages-sign-up-jsx" */)
}

